import {
  ROUTE_APPROVE_TIME_TIMESHEETS,
  APROVED_ALL_BASIC_ACCESS,
  REPORTS_BASIC_ACCESS,
  ROUTE_QUERY_TIMESHEETS,
  ROUTE_TIMESHEETS,
  ROUTE_TIMESHEETS_REPORTS,
  TIME_ADMIN_ACCESS,
  TIME_SUPER_ADMIN_ACCESS,
  TIME_BASIC_ACCESS,
  TIMESHEETS_BASIC_ACCESS,
} from "../../SharedModule/utils/constants";
import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";

export const LeftNavBarTimesheets = () => {
  return (
    <LeftNavBar>
      <LeftNavBarSection
        mainTitle="Time"
        biIconName="BiAlarm"
        asuiteAccess={true}
        asuiteRolesAccess={[TIMESHEETS_BASIC_ACCESS]}
        theHubAccess={false}
        role={[]}
        listOfLinks={[
          {
            text: "Enter Time",
            route: ROUTE_TIMESHEETS,
            asuiteAccess: true,
            asuiteRolesAccess: [TIMESHEETS_BASIC_ACCESS],
            theHubAccess: false,
            role: [],
          },
          {
            text: "Approve time",
            route: ROUTE_APPROVE_TIME_TIMESHEETS,
            asuiteAccess: true,
            asuiteRolesAccess: [APROVED_ALL_BASIC_ACCESS],
            theHubAccess: true,
            role: TIME_ADMIN_ACCESS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reports"
        biIconName="BiBarChartAlt2"
        asuiteAccess={true}
        asuiteRolesAccess={[TIMESHEETS_BASIC_ACCESS, REPORTS_BASIC_ACCESS]}
        theHubAccess={false}
        role={[]}
        listOfLinks={[
          {
            text: "Query",
            route: ROUTE_QUERY_TIMESHEETS,
            asuiteAccess: true,
            asuiteRolesAccess: [TIMESHEETS_BASIC_ACCESS],
            theHubAccess: false,
            role: [],
          },
          {
            text: "Time Reports",
            route: ROUTE_TIMESHEETS_REPORTS,
            asuiteAccess: true,
            asuiteRolesAccess: [REPORTS_BASIC_ACCESS],
            theHubAccess: true,
            role: TIME_ADMIN_ACCESS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reimbursements"
        biIconName="BiDollar"
        asuiteAccess={true}
        asuiteRolesAccess={[TIMESHEETS_BASIC_ACCESS]}
        theHubAccess={true}
        role={TIME_BASIC_ACCESS}
        listOfLinks={[
          {
            text: "Reimbursements",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/reimbreport.asp",
            asuiteAccess: true,
            asuiteRolesAccess: [TIMESHEETS_BASIC_ACCESS],
            theHubAccess: true,
            role: TIME_BASIC_ACCESS,
          },
          {
            text: "Expense Report",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/Expense_Report.asp",
            asuiteAccess: true,
            asuiteRolesAccess: [TIMESHEETS_BASIC_ACCESS],
            theHubAccess: true,
            role: TIME_BASIC_ACCESS,
          },
        ]}
      />
    </LeftNavBar>
  );
};
