export const hasAccessToTimesTheHub = (activeEmail: any) => {
  const arrayOfUsers: string[] = [
    "glozzia@itx.com",
    "israer@itx.com",
    "alejandrot@itx.com",
    "evicario@itx.com",
    "egranados@itx.com",
    "aestrada@itx.com",
    "apochettino@itx.com",
    "asuiteqa@itx.com",
    "bloughner@itx.com",
    "nmendez@itx.com",
    "rvillasana@itx.com",
    "crosica@itx.com",
    "thehub-asuitetesting@itx.com"
  ];

  if (activeEmail && arrayOfUsers.indexOf(activeEmail.toLowerCase()) > -1) {
    return true;
  }
  return false;
};
