import { EntryHoursBox } from "../components/EntryHoursBox";
import {
  concatProjectsWorkOrders,
  empty,
  emptyProjectWorkOrder,
  getProjectWorkOrder,
  getValueFromSelect,
} from "../utils/timesUtils";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import { useSelector } from "react-redux";
import { newUuid } from "../../SharedModule/utils/uuid";
import { TimeEntryForm } from "../types";
import { useEntryHourBox } from "../hooks/useEntryHourBox";
import { Spinner } from "../../SharedModule/components/Spinner";
import {
  BAMBOO_HR,
  billableIds,
  PROJ_UNPAID_TIME_OFF_ID,
  TASKTYPE_HOLIDAY_ID,
} from "../../SharedModule/utils/constants";

export const ModalTimeEntry = (props: any) => {
  const MetaTimesInfo = useSelector(selectMetaTimesData);

  // Should use concat, when we need to find a specific project
  const actualProjectsWorkOrders = concatProjectsWorkOrders(props.projectList);

  const { isLoading, isTimeAdmin, isSuperTimeAdmin, selectProjectOrWorkOrder } =
    useEntryHourBox(props.userIdParam);

  // When user is not admin, should not see PROJ_UNPAID_TIME_OFF_ID - ASUITE11-2464
  let selectProjectOrWorkOrderAfterFilter = selectProjectOrWorkOrder;
  if (!isTimeAdmin && selectProjectOrWorkOrder[1].options.length > 0) {
    selectProjectOrWorkOrderAfterFilter[1].options =
      selectProjectOrWorkOrder[1].options.filter(
        (project) => project.entityId !== PROJ_UNPAID_TIME_OFF_ID
      );
  }

  let concatedSelectProjectOrWorkOrder = concatProjectsWorkOrders(
    selectProjectOrWorkOrderAfterFilter
  );

  const getSpecificProjects = () => {
    return props.isQueryPage
      ? concatedSelectProjectOrWorkOrder
      : actualProjectsWorkOrders;
  };

  const getProjects = () => {
    return props.isQueryPage
      ? selectProjectOrWorkOrderAfterFilter
      : props.projectList;
  };

  // function to wait projects loaded
  const findProjectWorkOrder = () => {
    let specificProjects = getSpecificProjects();
    return props.editMode
      ? getProjectWorkOrder(specificProjects, props.entry.entityId)
      : props.entryInitalInfo
      ? getProjectWorkOrder(specificProjects, props.entryInitalInfo.entityId)
      : emptyProjectWorkOrder;
  };

  const getInactiveProjectName = () => {
    let wo, project;
    if (props.isQueryPage) {
      wo =
        "Work Order " +
        props.entry.entityId +
        " - " +
        props.entry.entityDescription +
        " (INACTIVE)";
      project =
        "Project " +
        (props.entry.projectId ? props.entry.projectId : props.entry.entityId) +
        " - " +
        props.entry.entityDescription +
        " (INACTIVE)";
    } else {
      wo =
        "Work Order " +
        props.entryInitalInfo.entityId +
        " - " +
        props.entryInitalInfo.entityName +
        " (INACTIVE)";
      project =
        "Project " +
        props.entryInitalInfo.entityId +
        " - " +
        props.entryInitalInfo.entityName +
        " (INACTIVE)";
    }
    let result = {
      value: 0,
      label: props.isQueryPage
        ? props.entry.categoryId === 3
          ? wo
          : project
        : props.entryInitalInfo.categoryId === 3
        ? wo
        : project,
    };
    return result;
  };

  let elem: TimeEntryForm = {
    // should combine entry canEdit with status pending (timeEntryStatusId === 1 or super admin and timeEntryStatusId === 2) to allow to edit
    entryCanEdit: props.editMode
      ? props.entry.timeEntrySourceId === 3 ||
        props.entry.timeEntryStatusId === 3 // imported from change gear or time entry locked
        ? false
        : (props.entry.canEdit && props.entry.timeEntryStatusId === 1) ||
          (props.entry.timeEntryStatusId === 2 && isSuperTimeAdmin)
      : true,
    entryCanDelete: props.editMode
      ? props.entry.timeEntrySourceId === 3 ||
        props.entry.timeEntryStatusId === 3 // imported from change gear or time entry locked
        ? false
        : props.entry.canDelete
      : true,
    entryId: props.editMode ? props.entry.timeEntryId : newUuid(),
    entryDate: props.editMode
      ? props.entryInitalInfo
        ? props.entryInitalInfo.modalDate
        : props.entry.entryDate
      : props.entryInitalInfo
      ? props.entryInitalInfo.modalDate
      : new Date().toISOString(),
    entryDateValid: true,
    entryProjectWorkOrder: findProjectWorkOrder(),
    entryProjectWorkOrderValid:
      findProjectWorkOrder() &&
      (props.editMode ||
        (props.entryInitalInfo && props.entryInitalInfo.entityId))
        ? true
        : false,
    entryHours: props.editMode ? props.entry.hours : "",
    entryHoursValid: props.editMode,
    entryTaskType: props.editMode
      ? getValueFromSelect(MetaTimesInfo.taskTypes, props.entry.taskTypeId)
        ? getValueFromSelect(MetaTimesInfo.taskTypes, props.entry.taskTypeId)
        : {
            value: props.entry.taskTypeId,
            label: props.entry.taskType,
          }
      : props.entryInitalInfo
      ? props.entryInitalInfo.taskType
        ? getValueFromSelect(
            MetaTimesInfo.taskTypes,
            props.entryInitalInfo.taskType
          )
        : props.entryInitalInfo.entryTaskType
        ? props.entryInitalInfo.entryTaskType
        : empty
      : empty,
    entryTaskTypeValid:
      props.editMode ||
      (props.entryInitalInfo && props.entryInitalInfo.taskType)
        ? true
        : false,
    entryDescription: props.editMode ? props.entry.description : "",
    entryIsBillable: props.editMode
      ? billableIds.includes(
          props.entry.timeEntryTypeId.toString().toLowerCase()
        )
      : true,
    entryNonBillableReason:
      props.editMode && props.entry.nonBillableReasonId
        ? getValueFromSelect(
            MetaTimesInfo.nonBillableReasons,
            props.entry.nonBillableReasonId
          )
          ? getValueFromSelect(
              MetaTimesInfo.nonBillableReasons,
              props.entry.nonBillableReasonId
            )
          : {
              value: props.entry.nonBillableReasonId,
              label: props.entry.nonBillableReason,
            }
        : empty,
    entryNonBillableReasonValid:
      props.editMode && props.entry.nonBillableReasonId ? true : false,
    isEntryValid: false,
    isEntryTouched: false,
  };

  let isHolidayFromBamboo =
    props.entry &&
    props.entry.timeEntrySourceId === 2 &&
    props.entry.source === BAMBOO_HR &&
    props.entry.taskTypeId === TASKTYPE_HOLIDAY_ID;

  return (
    <>
      {isLoading && (
        <Spinner
          style={{
            marginLeft: "50%",
          }}
        />
      )}
      {!isLoading && (
        <EntryHoursBox
          title={props.editMode ? "Edit time entry" : "Add time"}
          state={elem}
          partialInactiveEntryProjectWorkOrder={
            !elem.entryProjectWorkOrder ? getInactiveProjectName() : null
          }
          btnCancel={props.btnCancel}
          projectsWorkOrders={getProjects()}
          isLoadingProjects={isLoading}
          isModal={true}
          editMode={props.editMode}
          userIdParam={props.userIdParam}
          reloadPage={props.reloadPage}
          resetPage={props.resetPage}
          holidays={props.holidays}
          multipleTimeEntries={
            props.actualTimeEntries && props.actualTimeEntries.length > 1
              ? props.actualTimeEntries
              : null
          }
          deleteEntry={props.deleteEntry}
          timeEntriesList={props.timeEntriesList}
          isQueryPage={props.isQueryPage}
          isHolidayFromBamboo={isHolidayFromBamboo}
          activeEntry={props.entry}
          isListWiew={props.isListWiew}
        />
      )}
    </>
  );
};
