import { DateTime } from "luxon";
import { CustomWeekDay } from "../../TimesModule/types";

// Dates
export const NOW = DateTime.now();
export const YESTERDAY = NOW.get("day") === 1 ? NOW : NOW.minus({ day: 1 });
export const THIS_YEAR_START = NOW.startOf("year");
export const THIS_MONTHS_START = NOW.startOf("month");
export const THIS_MONTHS_END = NOW.endOf("month");
export const LAST_MONTHS_START = NOW.minus({ month: 1 }).startOf("month");
export const LAST_MONTHS_END = NOW.minus({ month: 1 }).endOf("month");

export const getDiffBetweenDates = (futureDate: string, pastDate: string) => {
  let date2: any = new Date(futureDate);
  let date1: any = new Date(pastDate);
  // Convert both dates to milliseconds
  const oneDayInMs = 24 * 60 * 60 * 1000; // Hours * minutes * seconds * milliseconds
  const diffInMs = Math.abs(date2 - date1); // Difference in milliseconds

  // Convert the difference to days
  return Math.ceil(diffInMs / oneDayInMs);
};

export const getDateFromISOFormat = (evalDate: string) => {
  return evalDate.split("T")[0];
};

export const getShortDayName = (index: number) => {
  switch (index) {
    case 0:
      return "SUN";
    case 1:
      return "MON";
    case 2:
      return "TUE";
    case 3:
      return "WED";
    case 4:
      return "THU";
    case 5:
      return "FRI";
    default:
      return "SAT";
  }
};

export const isToday = (date: Date) => {
  const today: Date = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getUTCDate() === today.getDate()
  );
};

export const isSameDay = (dateString: string, d2: Date) => {
  let d1 = DateTime.fromISO(dateString, { zone: "utc" }).setLocale("en-US");
  return (
    d1.year === d2.getFullYear() &&
    d1.month - 1 === d2.getMonth() &&
    d1.day === d2.getUTCDate()
  );
};

export const isWeekday = (date: Date) => date.getDay() % 6 !== 0;

export const isHoliday = (day: Date, holidays: string[]) => {
  let response = false;
  if (holidays.length > 0) {
    holidays.forEach((element) => {
      if (isSameDay(element, day)) {
        response = true;
      }
    });
  }
  return response;
};

export const getPreviousWorkableDay = (value: number, holidays: string[]) => {
  let yesterday: any = new Date(
    new Date().setDate(new Date().getUTCDate() - value)
  );
  if (isWeekday(yesterday) && !isHoliday(yesterday, holidays)) {
    return new Date(yesterday.setHours(0, 0, 0, 0));
  }
  return getPreviousWorkableDay(value + 1, holidays);
};

export const getWeekFullDate = (
  actualWeek: CustomWeekDay[],
  dayNumber: number
) => {
  let result;
  actualWeek.forEach((element) => {
    if (element.numDay === dayNumber) {
      result = element.fullDate;
    }
  });
  return result;
};

export const isDateAfterToday = (date: Date) => {
  let today = new Date();
  if (date > today) {
    return true;
  } else {
    return false;
  }
};

export const isTodayBetween1stAnd3rdBusinessDay = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  let businessDays: any[] = [];
  let dayCount = 0;

  // Start from the 1st day of the month and find the first 3 business days
  for (let day = 1; day <= 31; day++) {
    let currentDate = new Date(year, month, day);

    // Stop if we are beyond the current month
    if (currentDate.getMonth() !== month) break;

    // Check if the current date is a weekday (Monday to Friday)
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // 0 is Sunday, 6 is Saturday
      businessDays.push(currentDate);
      dayCount++;

      // Stop once we have the first 3 business days
      if (dayCount === 3) break;
    }
  }

  // Check if today is one of the first 3 business days
  return businessDays.some(
    (businessDay) => businessDay.getDate() === today.getDate()
  );
};

export const getPrevSunday = (pageDate: Date) => {
  let actualDate = new Date(
    pageDate.getFullYear(),
    pageDate.getMonth(),
    pageDate.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(
    actualDate.setDate(actualDate.getUTCDate() - actualDate.getDay())
  );
};

// get next day from sunday
export const getNextWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(actualDate.setDate(actualDate.getUTCDate() + days));
};

// days will be number to discount
export const getLastWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(actualDate.setDate(actualDate.getUTCDate() - days));
};

// improve logic to check only one date if some of them are null
export const isBetweenTwoDates = (
  dateToCompare: string,
  minorDate: Date | null,
  greaterDate: Date | null
) => {
  let response: boolean = false;
  if (minorDate && greaterDate) {
    response =
      getDateFromISOFormat(minorDate.toISOString()) <=
        getDateFromISOFormat(dateToCompare) &&
      getDateFromISOFormat(dateToCompare) <=
        getDateFromISOFormat(greaterDate.toISOString());
  } else if (!minorDate && greaterDate) {
    response =
      getDateFromISOFormat(dateToCompare) <=
      getDateFromISOFormat(greaterDate.toISOString());
  } else if (minorDate && !greaterDate) {
    response =
      getDateFromISOFormat(minorDate.toISOString()) <=
      getDateFromISOFormat(dateToCompare);
  }
  return response;
};
