import { createSlice } from "@reduxjs/toolkit";
import {
  getLastWeekDay,
  THIS_MONTHS_START,
  YESTERDAY,
} from "../../../SharedModule/utils/dateUtils";

type TimeConfirmationState = {
  keepFilters: boolean;
  filters: {
    onlyUs: boolean;
    startDate: string;
    endDate: string;
    costCenter: Array<any> | null;
  };
};

const today = new Date();

const initialState: TimeConfirmationState = {
  keepFilters: false,
  filters: {
    onlyUs: false,
    startDate: THIS_MONTHS_START.toString(),
    endDate:
      YESTERDAY.weekday !== 1
        ? YESTERDAY.toString()
        : getLastWeekDay(2, today).toISOString(), // should get previous friday if yesterday is monday
    costCenter: null,
  },
};

export const timeConfirmationSlice = createSlice({
  name: "timeConfirmation",
  initialState,
  reducers: {
    keepFilters: (state, action) => {
      state.keepFilters = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilters, keepFilters } = timeConfirmationSlice.actions;

export const SelectFilters = (state: any) => {
  return state.timeConfirmation.keepFilters
    ? state.timeConfirmation.filters
    : initialState.filters;
};

export const GetFilters = (state: any) => {
  return state.timeConfirmation.filters;
};

export const SelectInitialStateFilters = () => {
  return initialState.filters;
};

export default timeConfirmationSlice.reducer;
